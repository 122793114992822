import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { isArray, isEmpty } from "lodash";
import uniqid from "uniqid";
import dayjs from "dayjs";

import styles from "./ArticleMasthead.module.scss";

const propTypes = {
	tags: PropTypes.array,
	title: PropTypes.string,
	readTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	author: PropTypes.string,
	date: PropTypes.string,
	heroImage: PropTypes.string,
};

const defaultProps = {
	tags: [],
	title: "",
	readTime: "",
	author: "",
	date: "",
	heroImage: "",
};

function ArticleMasthead({ tags, title, readTime, author, date, heroImage }) {
	return (
		<>
			<div className={styles.wrapper__content}>
				{isArray(tags) && !isEmpty(tags) && (
					<section className={styles.tags__wrapper}>
						{tags.map((item, idx) => {
							console.log("item:", item);
							item && (
								<Link
									key={uniqid()}
									className={styles.tags__item}
									to={`/blog?tag=${item.toLowerCase()}`}
								>
									{item}
									{tags.length > 1 && idx !== tags.length - 1 && <span>, </span>}
								</Link>
							);
						})}
					</section>
				)}

				<h1 className={styles.article__title}>{title}</h1>
				<p className={styles.article__author}>Written by {author}</p>
				<div className={styles.article__dateRead}>
					Posted on {dayjs(date).format("MMMM D, YYYY")} {readTime}mins read
				</div>
			</div>

			<div className={styles.article__heroWrapper}>
				<img
					src={heroImage ? heroImage : "/img/blog-placeholder.jpg"}
					alt={title}
					className={styles.article__heroImgEl}
				/>
			</div>
		</>
	);
}

ArticleMasthead.propTypes = propTypes;
ArticleMasthead.defaultProps = defaultProps;

export default ArticleMasthead;
