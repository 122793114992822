import React from "react";
import PropTypes from "prop-types";

import styles from "./ArticleAuthor.module.scss";

const propTypes = {
	author: PropTypes.string,
};

const defaultProps = {
	author: null,
};

function ArticleAuthor({ author }) {
	return (
		<section className={styles.wrapper__main}>
			<div className={styles.wrapper__content}>
				<section className={styles.author__imgWrapper}>
					<img className={styles.author__imgEl} src={"/img/logo-symbol.svg"} alt={author} />
				</section>

				<section className={styles.author__copyWrapper}>
					<h1 className={styles.author__authorName}>About {author}</h1>
					<h2 className={styles.author__description}>
						We are purposefully small. And we are proud of it. Being small gives us the agility to
						work closer, to forge deeper relationships and to co-create amazing designs with you.
						Each design experience begins with a conversation. Drop us a message and let’s start
						chatting.
					</h2>
				</section>
			</div>
		</section>
	);
}

ArticleAuthor.propTypes = propTypes;
ArticleAuthor.defaultProps = defaultProps;

export default ArticleAuthor;
