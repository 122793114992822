import React from "react";
import PropTypes from "prop-types";
import { isObject, isEmpty } from "lodash";
import { htmlifyMarkdown } from "../../utils";

import SEO from "../../components/SEO/SEO";
import ArticleMasthead from "../../components/Blog/ArticleMasthead/ArticleMasthead";
import ArticleAuthor from "../../components/Blog/ArticleAuthor/ArticleAuthor";
import ArticleNextPost from "../../components/Blog/ArticleNextPost/ArticleNextPost";

import styles from "./BlogArticleLayout.module.scss";

const propTypes = {
	seoTitle: PropTypes.string,
	seoDescription: PropTypes.string,
	tags: PropTypes.array,
	title: PropTypes.string,
	readTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	author: PropTypes.string,
	date: PropTypes.string,
	heroImage: PropTypes.string,
	content: PropTypes.string,
	pathname: PropTypes.string,
	nextPostData: PropTypes.object,
	includeSEO: PropTypes.bool,
};

const defaultProps = {
	seoTitle: null,
	seoDescription: null,
	tags: [],
	title: "",
	readTime: "",
	author: "",
	date: "",
	heroImage: "",
	content: "",
	pathname: "",
	nextPostData: null,
	includeSEO: true,
};

function BlogArticleLayout({
	seoTitle,
	seoDescription,
	tags,
	title,
	readTime,
	author,
	date,
	heroImage,
	content,
	pathname,
	nextPostData,
	includeSEO,
}) {
	return (
		<>
			<section className={styles.wrapper__main}>
				{includeSEO && (
					<SEO
						title={seoTitle || title}
						description={seoDescription}
						image={heroImage}
						pathname={pathname}
						article
					/>
				)}

				<ArticleMasthead
					tags={tags}
					title={title}
					readTime={readTime}
					author={author}
					date={date}
					heroImage={heroImage}
				/>

				<section className={styles.wrapper__content}>
					<div
						dangerouslySetInnerHTML={{ __html: htmlifyMarkdown(content) }}
						className={styles.article__content}
					/>
				</section>
			</section>
			<ArticleAuthor author={author} />
			{isObject(nextPostData) ||
				(!isEmpty(nextPostData) && <ArticleNextPost data={nextPostData} />)}
		</>
	);
}

BlogArticleLayout.propTypes = propTypes;
BlogArticleLayout.defaultProps = defaultProps;

export default BlogArticleLayout;
