import React from "react";
import PropTypes from "prop-types";

import PostItem from "../PostItem/PostItem";

import styles from "./ArticleNextPost.module.scss";

const propTypes = {
	data: PropTypes.object,
};

const defaultProps = {
	data: null,
};

function ArticleNextPost({ data }) {
	const {
		fields: { slug },
		frontmatter: { blogAuthor: author, date, readTime, heroImage, tags, title },
	} = data;

	return (
		<section className={styles.wrapper__main}>
			<p className={styles.wrapper__title}>Next Post</p>

			<PostItem
				path={slug}
				tags={tags}
				heroImage={heroImage}
				title={title}
				author={author}
				date={date}
				readTime={readTime}
			/>
		</section>
	);
}

ArticleNextPost.propTypes = propTypes;
ArticleNextPost.defaultProps = defaultProps;

export default ArticleNextPost;
