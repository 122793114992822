import React from "react";
import { graphql } from "gatsby";
import { findIndex } from "lodash";
import PropTypes from "prop-types";
import BlogArticleLayout from "../layouts/BlogArticleLayout/BlogArticleLayout";

const propTypes = {
	data: PropTypes.object.isRequired,
	/** pathname for linking */
	uri: PropTypes.string.isRequired,
};

const BlogPost = ({ data, uri }) => {
	const {
		currentBlogPost: {
			childMarkdownRemark: {
				frontmatter: {
					blog_seo_title,
					blog_seo_description,
					tags,
					readTime,
					title,
					blogAuthor,
					date,
					heroImage,
					blogContent,
				},
				fields: { slug },
			},
		},
		allPosts: { edges: allPosts },
	} = data;

	function getNextPost() {
		let nextPostIndex =
			findIndex(allPosts, (i) => {
				return i.node.childMarkdownRemark.fields.slug === slug;
			}) + 1;

		if (nextPostIndex >= allPosts.length) {
			nextPostIndex = 0;
		}

		return allPosts[nextPostIndex].node.childMarkdownRemark;
	}

	return (
		<BlogArticleLayout
			seoTitle={blog_seo_title}
			seoDescription={blog_seo_description}
			tags={tags}
			readTime={readTime}
			title={title}
			author={blogAuthor}
			date={date}
			heroImage={heroImage}
			content={blogContent}
			pathname={uri}
			nextPostData={getNextPost()}
		/>
	);
};

export const query = graphql`
	query($slug: String!) {
		currentBlogPost: file(childMarkdownRemark: { fields: { slug: { eq: $slug } } }) {
			childMarkdownRemark {
				frontmatter {
					blog_seo_title
					blog_seo_description
					heroImage
					tags
					title
					date
					blogContent
					readTime
					blogAuthor
				}
				fields {
					slug
				}
			}
		}

		allPosts: allFile(
			filter: { relativeDirectory: { eq: "blog" } }
			sort: { order: DESC, fields: childMarkdownRemark___frontmatter___date }
		) {
			edges {
				node {
					id
					name
					childMarkdownRemark {
						id
						frontmatter {
							blogAuthor
							date
							readTime
							title
							heroImage
							tags
						}
						fields {
							slug
						}
					}
				}
			}
		}
	}
`;

BlogPost.propTypes = propTypes;
export default BlogPost;
